<template>
  <BaseContainer
    bg-color="02"
    class="c-account"
  >
    <Accordion>
      <AccordionItem
        :title="profile.title"
        icon="user"
        icon-width="20"
        icon-color="primary"
        header-display="block"
        :keep-open="!isMobile"
      >
        <template #accordion-content>
          <form
            @submit.prevent="handleSubmit"
            @invalid.capture.prevent="validate"
            class="c-account__form"
          >
            <div class="c-account__formGroup">
              <div class="-gridBox">
                <BaseTextBox
                  :label="profile.fields.name.label"
                  required="true"
                  :placeholder="profile.fields.name.placeholder"
                  id="name"
                  name="name"
                  type="text"
                  v-model="form.name"
                  :ref="el => { inputEl['name'] = el }"
                />
                <BaseTextBox
                  :label="profile.fields.surname.label"
                  required="true"
                  :placeholder="profile.fields.surname.placeholder"
                  id="surname"
                  name="surname"
                  type="text"
                  v-model="form.surname"
                  :ref="el => { inputEl['surname'] = el }"
                />
              </div>
              <div class="-gridBox">
                <BaseTextBox
                  :label="profile.fields.email.label"
                  required="true"
                  :placeholder="profile.fields.email.placeholder"
                  id="email"
                  name="email"
                  type="email"
                  v-model="form.email"
                  :ref="el => { inputEl['email'] = el }"
                />
                <BaseTextBox
                  :label="profile.fields.phoneNumber.label"
                  :placeholder="profile.fields.phoneNumber.placeholder"
                  id="mobile"
                  name="mobile"
                  type="text"
                  v-model="form.mobile"
                  :ref="el => { inputEl['mobile'] = el }"
                  required
                />
              </div>
              <div class="-gridBox">
                <BaseTextBox
                  :label="profile.fields.password.label"
                  :placeholder="profile.fields.password.placeholder"
                  name="change-password"
                  type="password"
                  id="change-password"
                  v-model="form.password"
                  autocomplete="new-password"
                />
              </div>
            </div>
            <BaseButton
              variant="solid"
              color="primary"
              type="submit"
              :display-loader="showLoader"
              :disabled="!onInputKeyPress || showLoader"
              :btn-label="profile.saveProfileBtnText"
            />
          </form>
        </template>
      </AccordionItem>
    </Accordion>
  </BaseContainer>
</template>

<script setup>
import {
  computed, inject, ref, watch,
} from 'vue';
import { useStore } from 'vuex';

import Accordion from '@/components/common/Accordion/Accordion.vue';
import AccordionItem from '@/components/common/Accordion/AccordionItem.vue';
import BaseTextBox from '@/components/common/BaseTextBox.vue';
import BaseContainer from '@/components/grid/BaseContainer.vue';

const store = useStore();

const showLoader = computed(() => store.state.loaders.showLoader);
const user = computed(() => store.state.auth.user);
const profile = computed(() => store.state.i18n.widgets.profile.contentBody.profile);
const isMobile = inject('isMobile');

const inputEl = ref([]);
const onInputKeyPress = ref(false);
const form = ref({
  name: user.value.name || '',
  surname: user.value.surname || '',
  email: user.value.email || '',
  password: '',
  mobile: user.value.mobile || '',
});

watch(() => user.value, value => {
  const {
    name, surname, email, mobile,
  } = value;

  form.value = {
    name, surname, email, mobile,
  };
}, { deep: true });

watch(() => form.value, () => {
  onInputKeyPress.value = true;
}, { deep: true });

const validate = evt => {
  inputEl.value[evt.target.id].validateForm(evt);
};

const handleSubmit = () => {
  const { password, ...rest } = form.value;
  const data = password !== '' ? form.value : rest;

  store.dispatch('auth/updateProfile', data);
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;

.c-account {
  border-radius: 3px;

  &::v-deep(.c-accordion) {
    border: 0;
  }

  &::v-deep(.c-accordionItem__header) {
    text-transform: uppercase;
  }

  &::v-deep(.c-accordionItem__content) {
    padding-top: 0;
  }

  &::v-deep(.c-accordionItem__icon) {
    margin-bottom: 10px;
  }

  &__form {
    height: 100%;
    padding-top: 10px;
    @include mx.d-flex(false, false, true);

    ::v-deep(.c-baseTextBox__input) {
      @include mx.use-bg-color('lightColor');
    }

    &Group {
      flex: auto;
    }
  }

  .-gridBox {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1px;

    &::v-deep(.c-baseButton.-default) {
      padding-left: 0;
    }

    @include mx.bp("desktop") {
      gap: 16px;
      grid-template-columns: repeat(2, 1fr);

      &::v-deep(.c-baseButton.-default) {
        padding-left: 24px;
      }
    }
  }
}

</style>
