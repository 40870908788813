<template>
  <component
    :is="to ? 'router-link' : 'button'"
    :class="['c-profileCard', `-var${variant}`]"
    :to="Trans.i18nRoutePath(to)"
  >
    <Icon
      :data="require(`@icons/${icon}.svg`)"
      :color="iconColor"
      width="20"
      class="h-mb-10"
    />
    <small
      class="c-profileCard__title"
      v-text="text"
    />
  </component>
</template>

<script setup>
import Trans from '@/mixins/translation';

const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  variant: {
    type: String,
    default: '01',
    validator: value => [ '01', '02' ].includes(value),
  },
  to: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
});

const iconColor = `var(--st-${props.variant === '01' ? 'primary' : 'bodyColorLtLight'})`;

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;
@use "src/assets/styles/abstracts/variables" as v;

.c-profileCard {
  @include mx.use-color('bodyColorLt');
  @include mx.use-bg-color('bg-02');

  display: flex;
  flex-direction: column;

  padding: 25px 20px;

  border: 2px solid var(--#{ v.$variable-prefix}bg-02);
  border-radius: v.$base-border-radius;

  &__title {
    text-transform: uppercase;

    font-weight: 700;
  }

  &.-var02 {
    @include mx.use-color('muted');
    @include mx.use-bg-color('bg-01');

    border: 2px solid var(--#{ v.$variable-prefix}bg-02);
  }
}
</style>
