<template>
  <Page :title="$t('widgets.profile.header.mainText')">
    <main class="l-profile">
      <BannerImageAndTitle
        :background="[
          {
            url: $t('widgets.profile.header.background[0].url'),
            format: $t('widgets.profile.header.background[0].format'),
            altText: $t('widgets.profile.header.background[0].altText'),
          },
          {
            url: $t('widgets.profile.header.background[1].url'),
            format: $t('widgets.profile.header.background[1].format'),
          },
        ]"
        :banner-title="$t('widgets.profile.header.mainText')"
      />
      <SectionContainer
        size="md"
        class="h-pv-20"
      >
        <Typography
          v-text="$t('widgets.profile.contentBody.headText')"
          variant="subtitle1"
        />
        <Typography
          v-text="$t('widgets.profile.contentBody.subText')"
          variant="body1"
        />
        <Typography
          v-if="isCreditActive"
          v-text="$t('widgets.profile.contentBody.creditBalance', { balance: creditBalance })"
          variant="body1"
        />
        <div class="l-profile__content h-mt-20">
          <Account />
          <ProfileCardBox
            v-for="(item) in items"
            :key="item.icon"
            :text="$t(item.text)"
            :icon="item.icon"
            :to="item.to"
          />
          <ProfileCardBox
            :text="$t('widgets.profile.contentBody.logout')"
            :icon="'logout'"
            :variant="'02'"
            @click="handleClick"
          />
          <BaseButton
            color="muted"
            :btn-label="$t('widgets.profile.contentBody.deleteAccount')"
            @click="confirmDelete"
          />
        </div>
      </SectionContainer>
    </main>
  </Page>
</template>

<script setup>
import {
  computed, inject, markRaw,
} from 'vue';
import { useStore } from 'vuex';

import Account from '@/components/Account.vue';
import Page from '@/components/Page.vue';
import BannerImageAndTitle from '@/components/banner/BannerImageAndTitle.vue';
import ProfileCardBox from '@/components/common/Card/ProfileCardBox.vue';
import DeletePrompt from '@/components/common/DeletePrompt.vue';
import { toast } from '@/toast-notification';

const { PROFILE_ADDRESS, PROFILE_NOTIFICATION } = inject('routePaths');

const store = useStore();
const showLoader = computed(() => store.state.showLoader);
const items = markRaw([
  {
    text: 'widgets.profile.contentBody.location',
    icon: 'location',
    to: PROFILE_ADDRESS,
  }, {
    text: 'widgets.profile.contentBody.notification',
    icon: 'notification',
    to: PROFILE_NOTIFICATION,
  }, /* {    text: 'widgets.profile.contentBody.preference',
    icon: 'wheat',
    to: PROFILE_PREFERENCES,
  } */
]);

const isCreditActive = computed(() => store.getters['reusable/getSetting']('credit_active'));
const creditBalance = computed(() => store.state.auth.user.credit);
const handleClick = () => store.dispatch('auth/logout');
const confirmDelete = () => {
  const content = {
    component: DeletePrompt,
    props: { showLoader },
    listeners: {
      click: () => {
        store.dispatch('auth/deleteCustomerAccount');
      },
    },
  };

  // id - it's used to dismiss toast
  toast(content, {
    timeout: false, id: 'deletePrompt', icon: false, closeButton: false,
  });
};

</script>

<style lang="scss" scoped>
@use "src/assets/styles/abstracts/mixins" as mx;

.l-profile {
  &__content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;

    @include mx.bp("tablet-wide") {
      padding-bottom: 60px;
      grid-template-columns: repeat(2, 1fr);

      &::v-deep(.c-account) {
        grid-row: 1 / 7;
      }
    }

    @include mx.bp("desktop") {
      align-items: baseline;
      grid-template-columns: 2fr 1fr 1fr;
      grid-template-rows: repeat(5, 95px);

      &::v-deep(.c-account),
      &::v-deep(.c-payment) {
        grid-row: 1 / 6;
        align-self: stretch;
      }
    }
  }
}
</style>
